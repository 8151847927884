import type { SdkConfig } from '@/models/sdk';
import Router from '@/sdk/router';
import { persistor, store } from '@/sdk/store';
import { createTheme, jssPreset, StylesProvider, ThemeProvider } from '@/utils/material';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { PageLoading } from '@propify-tenant-client/common';
import {
  EnvironmentService,
  initializeApi,
  SentryService,
  setOrganizationToken,
} from '@propify-tenant-client/services';
import { Big } from 'big.js';
import { create } from 'jss';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// @ts-ignore
import retargetEvents from 'react-shadow-dom-retarget-events';
import { PersistGate } from 'redux-persist/integration/react';

Big.RM = 2;
Big.DP = 10;

initializeApi();

const defaultConfig: SdkConfig = {
  elementSelector: '#phoenix-sdk-root',
  debug: false,
  searchOnMount: true,
  shadowMainHeight: '100vh',
  theme: {
    typography: {
      fontSize: 14,
      fontFamily:
        '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    },
  },
};

(window as any).phoenixSDK = {
  init(config: SdkConfig = {}) {
    if (!config.token) {
      throw new Error('Invalid Token provided');
    }

    window.propifySdk = true;
    setOrganizationToken(config.token);

    EnvironmentService.init();
    SentryService.init({ integrations: [] });
    const finalConfig = {
      ...defaultConfig,
      ...config,
    };

    if (finalConfig.shadowRoot) {
      const shadowRoot = document.querySelector(finalConfig.elementSelector!)!.shadowRoot!;

      const mountPoint = document.createElement('span');
      const reactRoot = shadowRoot.appendChild(mountPoint);

      const portalContainer = reactRoot.appendChild(document.createElement('div'));
      portalContainer.className = 'propify-shadow';

      const jss = create({
        ...jssPreset(),
        insertionPoint: reactRoot,
      });

      const theme = createTheme(finalConfig.theme, {
        props: {
          MuiPopover: {
            container: (): any => {
              return portalContainer;
            },
          },
          MuiDialog: {
            container: (): any => {
              return portalContainer;
            },
            disableEnforceFocus: true,
          },
          MuiSelect: {
            container: (): any => {
              return portalContainer;
            },
          },
          MuiTooltip: {
            PopperProps: {
              // do not forget to nest it here, Tooltip does not have portal props because its composed with Popper
              container: portalContainer,
              disablePortal: true,
            },
          },
          MuiModal: {
            container: () => {
              return portalContainer;
            },
            disablePortal: true,
          },
          MuiMenu: {
            container: () => {
              return portalContainer;
            },
          },
          MuiMenuItem: {
            container: () => {
              return portalContainer;
            },
          },
          MuiToolbar: {
            container: () => {
              return portalContainer;
            },
          },
          MuiDataGrid: {
            componentsProps: {
              columnMenu: portalContainer,
              menu: portalContainer,
              columnsPanel: portalContainer,
              filterPanel: portalContainer,
              panel: portalContainer,
              preferencesPanel: portalContainer,
            },
          },
        },
      });

      const styleRoot = document.createElement('style');
      styleRoot.setAttribute(
        'textContent',
        `
      .propify-shadow {
        line-height: initial;
        font-size: initial;
        font-family: ${theme.typography.fontFamily};
      }
      `,
      );
      reactRoot.appendChild(styleRoot);

      const emotionRoot = document.createElement('style');
      reactRoot.appendChild(emotionRoot);

      const cache = createCache({
        key: 'css',
        prepend: true,
        container: emotionRoot,
      });

      ReactDOM.render(
        <StrictMode>
          <Provider store={store}>
            <PersistGate loading={<PageLoading />} persistor={persistor}>
              <StylesProvider jss={jss}>
                <ThemeProvider theme={theme}>
                  <CacheProvider value={cache}>
                    <Router config={finalConfig} />
                  </CacheProvider>
                </ThemeProvider>
              </StylesProvider>
            </PersistGate>
          </Provider>
        </StrictMode>,
        portalContainer,
      );

      retargetEvents(shadowRoot);
    } else {
      const theme = createTheme(finalConfig.theme);

      ReactDOM.render(
        <Provider store={store}>
          <PersistGate loading={<PageLoading />} persistor={persistor}>
            <ThemeProvider theme={theme}>
              <Router config={finalConfig} />
            </ThemeProvider>
          </PersistGate>
        </Provider>,
        document.querySelector(finalConfig.elementSelector!),
      );
    }

    // eslint-disable-next-line no-console
    console.log('> PhoenixSDK initialized');
  },
};

// eslint-disable-next-line no-console
console.log('> PhoenixSDK loaded');
