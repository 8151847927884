import type { Model } from '@/models/connect';
import SdkModel from '@/models/sdk';
import UnitModel from '@/models/unit';
import { initialize } from '@/utils/store';
import createSagaMiddleware from '@redux-saga/core';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const models: Model[] = [SdkModel, UnitModel];

const { rootReducer, sagas } = initialize(models);

const sagaMiddleWare = createSagaMiddleware({
  onError(e: Error) {
    // eslint-disable-next-line no-console
    console.log(e.message);
  },
});

const persistedReducer = persistReducer(
  {
    key: 'nhrapp',
    storage,
  },
  rootReducer,
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [sagaMiddleWare],
});

sagas.forEach((saga) => sagaMiddleWare.run(saga));

export const persistor = persistStore(store);
