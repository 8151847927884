import { GAEvent } from '@/utils/utils';
import { PageLoading } from '@propify-tenant-client/common';
import { UnitHooks, type Unit } from '@propify-tenant-client/services';
import { UnitDetails, UnitNotFound } from '@propify-tenant-client/units';
import isEmpty from 'lodash/isEmpty';
import type { FC } from 'react';
import { useParams } from 'react-router';

const UnitPage: FC = () => {
  const { id: unitId } = useParams();
  const id = Number(unitId);

  const { data: unit, isValidating: loading } = UnitHooks.useUnit([id, true]);

  const onRegisterSubmit = () => {
    GAEvent('User', 'Register');
  };

  const onLoginSubmit = () => {
    GAEvent('User', 'Login');
  };

  if (loading) {
    return <PageLoading />;
  }

  if (isEmpty(unit)) {
    return <UnitNotFound />;
  }

  return (
    <UnitDetails
      unit={unit as Unit}
      onLoginSubmit={onLoginSubmit}
      onRegisterSubmit={onRegisterSubmit}
    />
  );
};

export default UnitPage;
