import type { ConnectState } from '@/models/connect';
import { getEnvironment } from '@propify-tenant-client/services';
import { type FC, useLayoutEffect } from 'react';
import ReactGA from 'react-ga';
import { useStore } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router';

const environment = getEnvironment();

const defaultTracker: ReactGA.Tracker = {
  trackingId: 'UA-180474954-3',
  gaOptions: {
    name: 'sdkTracker',
  },
};

const Root: FC = () => {
  const config = useStore<ConnectState>().getState()?.sdk?.config;

  const navigate = useNavigate();
  const location = useLocation();

  const allTrackers = [...(config?.trackers || []), defaultTracker];

  const trackerNames = allTrackers
    .map((t) => t.gaOptions?.name)
    .filter((name) => typeof name === 'string') as string[];

  useLayoutEffect(() => {
    if (environment !== 'local') {
      ReactGA.initialize(allTrackers);
    }
  }, []);

  useLayoutEffect(() => {
    if (environment !== 'local') {
      ReactGA.pageview(location.pathname + location.search, trackerNames);
    }
  }, [location.pathname, location.search]);

  useLayoutEffect(() => {
    let hash = window.location.hash || '/';
    if (hash.startsWith('#')) {
      hash = hash.substring(1);
    }
    navigate(hash || '/', { replace: true });
  }, [navigate]);

  return <Outlet />;
};

export default Root;
