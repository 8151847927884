import type { ConnectProps, ConnectState } from '@/models/connect';
import { NoResults } from '@/sdk/pages/NoResults';
import type { Theme, WithStyles } from '@/utils/material';
import {
  Box,
  Card,
  CircularProgress,
  createStyles,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
  withTheme,
} from '@/utils/material';
import { GAEvent } from '@/utils/utils';
import { getLocationParams, Menu, SessionContextProvider } from '@propify-tenant-client/common';
import type { ActivePlace, Enum, Unit } from '@propify-tenant-client/services';
import {
  getSdkUnitListClassName,
  PointInMap,
  Toolbar,
  UnitList,
  UnitsMap,
} from '@propify-tenant-client/units';
import classNames from 'classnames';
import type { CSSProperties, FC } from 'react';
import { memo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';

type SortDirection = 'ASC' | 'DES';
type Sort = { key: string; direction: SortDirection };

const MAX_UNITS_IN_LIST = 100;

const styles = (theme: Theme) =>
  createStyles({
    main: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        overflow: 'auto',
      },
    },
    mapAndList: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.up('md')]: {
        overflow: 'auto',
      },
    },
    list: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100vw',
      [theme.breakpoints.up('md')]: {
        minWidth: 700,
        maxWidth: 700,
        overflow: 'auto',
      },
    },
    map: {
      flex: 1,
    },
    image: {
      height: '100px',
      width: '100%',
      textAlign: 'center',
      margin: 'auto',
    },
    header: {
      position: 'sticky',
      top: 0,
      margin: '0 0.5em',
      zIndex: 10,
    },
    headerTitle: {
      display: 'flex',
      margin: '0.5em',
      justifyContent: 'space-between',
      alignItems: 'center',
      zIndex: 10,
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    headerSubtitle: {
      [theme.breakpoints.down('xs')]: {
        marginBottom: '0.5em',
      },
    },
  });

type Props = WithStyles<typeof styles> &
  ReturnType<typeof mapStateToProps> &
  ConnectProps & {
    showMap: boolean;
  };

interface SortOption {
  value: string;
  sort: Sort;
  label: string;
}

const getSortOptions = (): SortOption[] => [
  {
    value: 'displayRent-ASC',
    sort: { key: 'listedRent', direction: 'ASC' },
    label: 'Rent (Low to High)',
  },
  {
    value: 'displayRent-DES',
    sort: { key: 'listedRent', direction: 'DES' },
    label: 'Rent (High to Low)',
  },
  {
    value: 'bedroomCount-DES',
    sort: { key: 'bedroomCount', direction: 'DES' },
    label: 'Bedrooms',
  },
  {
    value: 'bathroomCount-DES',
    sort: { key: 'bathroomCount', direction: 'DES' },
    label: 'Bathrooms',
  },
  {
    value: 'squareFeet-DES',
    sort: { key: 'squareFeet', direction: 'DES' },
    label: 'Square Feet',
  },
];

const defaultSort: Sort = { key: 'listedRent', direction: 'ASC' };

const App: FC<Props> = ({
  dispatch,
  classes,
  config,
  units = [],
  visibleUnits,
  blankPage,
  activePlace,
  loading,
  showMap,
  filter,
  stateEnums,
  states,
  mapData,
}) => {
  const [sortBy, setSortBy] = useState<Sort>(defaultSort);
  const location = useLocation();

  useEffect(() => {
    dispatch({
      type: 'sdk/sortBy',
      payload: sortBy,
    });
  }, [sortBy, dispatch]);

  const getPoints = useCallback(() => {
    return units
      .filter((unit: Unit) => !!unit.property.address.location)
      .map((unit: Unit, key: number) => ({
        id: unit.id,
        latitude: unit.property.address.location?.latitude,
        longitude: unit.property.address.location?.longitude,
        key,
        render: () => <PointInMap unit={unit} />,
        to: `/units/${unit.id}`,
      }));
  }, [units]);

  const fetchUnits = () => {
    dispatch({ type: 'sdk/fetchUnits', payload: config.unitParameters });
  };

  const sdkRedirect = () => {
    dispatch({ type: 'sdk/redirect' });
  };

  const setFilter = (newFilter: Record<string, any>) => {
    dispatch({
      type: 'sdk/filter',
      payload: newFilter,
    });
  };

  const setStateEnums = (newStateEnums: Enum[]) => {
    dispatch({ type: 'sdk/setStateEnums', payload: newStateEnums });
  };

  const onRegisterSubmit = () => {
    GAEvent('User', 'Register');
  };

  const onLoginSubmit = () => {
    GAEvent('User', 'Login');
  };

  const onScheduleShowingStart = () => {
    GAEvent('User', 'Schedule showing');
  };

  const setActivePlace = (newActivePlace?: Partial<ActivePlace>) => {
    dispatch({
      type: 'sdk/setActivePlace',
      payload: newActivePlace,
    });
  };

  const updateMapData = (data: Record<string, any>) => {
    dispatch({
      type: 'sdk/updateMapData',
      payload: data,
    });
  };

  const toolbarParams = getLocationParams(location);

  const mapProps = {
    points: getPoints(),
    activePlace,
  };

  const mainStyle: CSSProperties = {};
  const mapStyle: CSSProperties = {};
  if (config.shadowRoot && !config.onlySearchBar) {
    mainStyle.height = config.shadowMainHeight;
    mapStyle.height = '100%';
    mapStyle.width = '100%';
  }

  const hasResults = !!units.length;
  const shown = Math.min(MAX_UNITS_IN_LIST, visibleUnits.length);
  const total = units.length;
  const sortOptions = getSortOptions();
  const sortValue =
    sortOptions.find(({ sort }) => sort.key === sortBy.key && sort.direction === sortBy.direction)
      ?.value ?? '';

  return (
    <SessionContextProvider>
      <div
        className={classNames(classes.main, getSdkUnitListClassName('container'))}
        style={mainStyle}
      >
        <Toolbar
          params={toolbarParams}
          fetchUnits={fetchUnits}
          filter={filter}
          sdkRedirect={sdkRedirect}
          setFilter={setFilter}
          setStateEnums={setStateEnums}
          stateEnums={stateEnums}
          states={states}
          onlySearchBar={config.onlySearchBar}
          redirectSearchTo={config.redirectSearchTo}
        />
        {loading && (
          <Box
            p={8}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
            <Box mt={1}>
              <Typography variant="caption">Loading properties...</Typography>
            </Box>
          </Box>
        )}
        {!config.onlySearchBar && hasResults && (
          <div className={classNames(classes.mapAndList, getSdkUnitListClassName('map-and-list'))}>
            <div className={classNames(classes.list, getSdkUnitListClassName('list'))}>
              {!!shown && !!total && (
                <div className={classNames(classes.header, getSdkUnitListClassName('header'))}>
                  <Card>
                    <div
                      className={classNames(
                        classes.headerTitle,
                        getSdkUnitListClassName('headerTitle'),
                      )}
                    >
                      <div
                        className={classNames(
                          classes.headerSubtitle,
                          getSdkUnitListClassName('headerSubtitle'),
                        )}
                      >
                        <Typography component="span">
                          Viewing {shown} homes out of {total}
                        </Typography>
                      </div>
                      <div>
                        <Menu
                          title="Sort By"
                          value={sortValue}
                          onChange={(option) =>
                            setSortBy(
                              sortOptions.find((sortOption) => sortOption.value === option.value)
                                ?.sort ?? defaultSort,
                            )
                          }
                          options={sortOptions}
                        />
                      </div>
                    </div>
                  </Card>
                </div>
              )}

              <UnitList
                selectionEnabled={showMap}
                maxDisplay={MAX_UNITS_IN_LIST}
                onLoginSubmit={onLoginSubmit}
                onRegisterSubmit={onRegisterSubmit}
                onScheduleShowingStart={onScheduleShowingStart}
                setActivePlace={setActivePlace}
                units={visibleUnits}
                activePlace={activePlace}
              />
            </div>

            {showMap && (
              <div
                className={classNames(classes.map, getSdkUnitListClassName('map'))}
                style={mapStyle}
              >
                <UnitsMap
                  {...mapProps}
                  setActivePlace={setActivePlace}
                  mapData={mapData}
                  updateMapData={updateMapData}
                />
              </div>
            )}
          </div>
        )}
        {!config.onlySearchBar && !hasResults && !loading && !blankPage && <NoResults />}
      </div>
    </SessionContextProvider>
  );
};

const mapStateToProps = (state: ConnectState) => ({
  config: state.sdk.config,
  blankPage: state.sdk.blankPage,
  units: state.sdk.filteredUnits,
  visibleUnits: state.sdk.visibleUnits,
  activePlace: state.sdk.activePlace,
  loading: state.loading.effects['sdk/fetchUnits'],
  filter: state.sdk.filter,
  stateEnums: state.sdk.stateEnums,
  states: state.sdk.states,
  mapData: state.sdk.mapData,
});

const withShowMapProp = (Component: any) => (props: any) => {
  const theme = useTheme();
  const showMap = useMediaQuery(theme.breakpoints.up('md'));
  return <Component showMap={showMap} {...props} />;
};

const AppWithStyles = withShowMapProp(
  withTheme(withStyles(styles, { name: 'App' })(connect(mapStateToProps)(memo(App)))),
);

export default AppWithStyles;
