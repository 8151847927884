/* eslint-disable react-refresh/only-export-components */
import type { ConnectProps } from '@/models/connect';
import type { SdkConfig } from '@/models/sdk';
import NoFoundPage from '@/pages/404';
import Root from '@/sdk/Root';
import { PageLoading } from '@propify-tenant-client/common';
import type { FC } from 'react';
import { lazy, Suspense, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { createHashRouter, RouterProvider } from 'react-router-dom';

const App = lazy(() => import('./pages/app'));
const Unit = lazy(() => import('./pages/unit'));

const router = createHashRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '/',
        element: (
          <Suspense fallback={<PageLoading />}>
            <App />
          </Suspense>
        ),
        errorElement: <NoFoundPage />,
      },
      {
        path: '/units/:id',
        element: (
          <Suspense fallback={<PageLoading />}>
            <Unit />
          </Suspense>
        ),
        errorElement: <NoFoundPage />,
      },
    ],
    errorElement: <NoFoundPage />,
  },
]);

type Props = ConnectProps & {
  config: Partial<SdkConfig>;
};

const AppRouter: FC<Props> = ({ config, dispatch }) => {
  useLayoutEffect(() => {
    dispatch({
      type: 'sdk/setConfig',
      payload: config,
    });
  }, []);

  return <RouterProvider router={router} />;
};

export default connect()(AppRouter);
